<template>
  <div class="container">
    <top-nav :banner="banner">
      <template #hover>
        <div class="banner-title">
          {{ globalData.serve.banner.title }}
        </div>
        <pre class="banner-subtitle"
          >{{ globalData.serve.banner.subtitle }}
              </pre
        >
      </template>
    </top-nav>
    <div class="support content-limit">
      <strip-title
        :title="globalData.serve.support.topic.title"
        :subtitle="globalData.serve.support.topic.subtitle"
      ></strip-title>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) of globalData.serve.support.list"
          :key="index"
        >
          <div class="left">
            <img class="img" :src="support[index]" alt="" />
          </div>
          <div class="right general">
            <div class="title">{{ item.title }}</div>
            <pre class="brief">{{ item.brief }}</pre>
          </div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import banner from "../asset/serve/serve_01.png";
import { mapGetters } from "vuex";
import serveimg1 from "../asset/serve/serve_02.png";
import serveimg2 from "../asset/serve/serve_03.png";
import serveimg3 from "../asset/serve/serve_04.png";
import serveimg4 from "../asset/serve/serve_05.png";
import serveimg5 from "../asset/serve/serve_06.png";

export default {
  data: function () {
    return {
      banner,
      support: [serveimg1, serveimg2, serveimg3, serveimg4, serveimg5],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$store.commit("setBannerChildIndex", 2);
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
    // })
  },
};
</script>
<style scoped lang="scss">
.banner-title {
  color: #3384e8;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  text-align: left;
  text-align: center;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 24px;
  margin-bottom: 64px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.support {
  padding: 80px 0;
  .list {
    margin-top: 112px;
    .item {
      margin: 0 auto;
      margin-bottom: 40px;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      &:nth-child(2n) {
        flex-direction: row-reverse;
        .general {
          margin-left: 0;
          margin-right: 40px;
        }
      }
      .general {
        flex: 1;
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        margin-left: 40px;
        overflow: hidden;
        @media screen and (max-width: 767px) {
          width: 100%;
          flex: auto;
        }
      }
      .left {
        flex: 1;
        .img {
          width: 100%;
        }
      }
      .right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;

        .title {
          color: white;
          font-size: 36px;
          margin-bottom: 30px;
          width: 100%;
          @media screen and (max-width: 1000px) {
            font-size: 24px;
          }
        }
        .brief {
          font-size: 24px;
          color: rgba($color: white, $alpha: 0.6);
          line-height: 2;
          @media screen and (max-width: 1000px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
      