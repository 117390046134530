<template>
  <div>
    <div
      class="nav"
      :style="{ background: `url(${banner}) no-repeat center/cover` }"
    >
      <!-- <img class="banner" :src="banner" alt="" /> -->
      <div class="content">
        <div class="content-limit">
          <img class="logo" src="../asset/index/index_26.png" alt="" />
          <div class="slogan">国家高新技术企业<br />国家3A资质认证</div>
          <div class="tabs">
            <div class="query">
              <div
                class="button"
                @mousedown.stop="switchNav"
                :style="{
                  background: `url(${navicon2}) no-repeat center/30px`,
                }"
              >
                <!-- <img class="button-icon" :src="navicon2" alt="" /> -->
              </div>
              <div class="list" v-if="showNav">
                <div
                  class="item"
                  v-for="(item, index) of globalData.common.navs"
                  :key="index"
                  :class="{ active: bannerIndex == index }"
                >
                  <div ref="tabs" class="inner" @click="nav(index)">
                    {{ item.name }}
                  </div>
                  <div class="child" v-if="typeof item.route !== 'string'">
                    <div class="child-inner">
                      <div
                        class="child-item"
                        :class="{
                          'child-active': bannerChildIndex == innerIndex,
                        }"
                        @mousedown="childNav(index, innerIndex)"
                        v-for="(innerItem, innerIndex) of item.route"
                        :key="innerIndex"
                      >
                        {{ innerItem.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="phone-content">
              <div class="phone" @click="call('400-8494-999')">
                <img
                  class="phone-icon"
                  src="../asset/index/index_63.png"
                  alt=""
                />
                全国服务热线:
                <div class="phone-num">400-8494-999</div>
              </div>
              <!-- <div class="phone" @click="call('133-5035-8828')">
                <img
                  class="phone-icon"
                  src="../asset/index/index_10.png"
                  alt=""
                />
                <div class="phone-num">133-5035-8828</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="hover content-limit">
        <div class="txt">
          <slot name="hover"></slot>
        </div>
        <div class="digital">
          <slot name="digital"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import storage from "../utils/utils.js";
import navicon2 from "../asset/index/navicon2.png";
export default {
  data() {
    return {
      showNav: true,
      onPhone: false,
      navicon2,
    };
  },
  props: {
    banner: {
      type: String,
      required: true,
    },
  },
  methods: {
    // ...mapMutations({
    //   setBannerIndex: "setBannerIndex",
    //   setBannerChildIndex: "setBannerChildIndex",
    // }),
    call(number) {
      window.location.href = `tel:${number}`;
    },
    getSize() {
      let width = document.documentElement.clientWidth;
      if (width <= 767) {
        this.showNav = false;
        this.onPhone = true;
      } else {
        this.showNav = true;
        this.onPhone = false;
      }
    },
    switchNav() {
      this.showNav = !this.showNav;
    },
    hideNav(e) {
      if (this.onPhone && !this.$refs?.tabs?.includes?.(e.target)) {
        this.showNav = false;
      }
    },
    nav(index) {
      let url = this.globalData.common.navs[index].route;
      if (typeof url === "string") {
        // this.setBannerIndex(index);
        // this.setBannerChildIndex(null);
        // storage.sStorage.set("navIndex", index);
        // storage.sStorage.set("navChildIndex", null);
        this.$router.push(url);
      }
    },
    childNav(index, innerIndex) {
      let url = this.globalData.common.navs[index].route[innerIndex].path;
      // this.setBannerIndex(index);
      // this.setBannerChildIndex(innerIndex);
      // storage.sStorage.set("navIndex", index);
      // storage.sStorage.set("navChildIndex", innerIndex);
      this.$router.push(url);
    },
    // getCurrentNavIndex() {
    //   let currentUrl = this.$route.path;
    //   this.globalData.common.navs.forEach((v, i) => {
    //     if (typeof v.route !== "string") {
    //       v.route.forEach((iv, ii) => {
    //         if (currentUrl === iv.path) {
    //           this.setBannerIndex(i);
    //           this.setBannerChildIndex(ii);
    //         }
    //       });
    //     } else {
    //       if (currentUrl === v.route) {
    //         this.setBannerIndex(i);
    //         this.setBannerChildIndex(null);
    //       }
    //     }
    //   });
    // },
  },
  computed: {
    // ...mapState({
    //   bannerIndex: "bannerIndex",
    //   bannerChildIndex: "bannerChildIndex",
    // }),
    ...mapGetters(["globalData"]),
    bannerIndex() {
      return this.globalData.common.navs.findIndex((which) => {
        if (typeof which.route === "string") {
          return which.route === this.$route.path;
        } else {
          return (
            which.route.findIndex((item) => item.path === this.$route.path) > -1
          );
        }
      });
    },
    bannerChildIndex() {
      if (this.bannerIndex === -1) return -1;
      const navs = this.globalData.common.navs || [];
      const nav = navs[this.bannerIndex];
      if (Array.isArray(nav.route)) {
        return nav.route.findIndex((item) => item.path === this.$route.path);
      }
      return -1;
    },
  },
  mounted() {
    // this.setBannerIndex(storage.sStorage.get("navIndex") || 0);
    // this.setBannerChildIndex(storage.sStorage.get("navChildIndex") || null);
    // this.getCurrentNavIndex();
    window.addEventListener("resize", this.getSize);
    this.getSize();
    window.addEventListener("mousedown", this.hideNav);
  },
  destroyed() {
    window.removeEventListener("resize", this.getSize);
    window.removeEventListener("mousedown", this.hideNav);
  },
};
</script>

<style scoped lang="scss">
.nav {
  font-size: 0;
  position: relative;
  padding-top: 100px;
  height: 640px;
  @media (max-width: 767px) {
    height: 700px;
    padding-top: 60px;
  }
}

.banner {
  width: 100%;
}

.content {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  position: fixed;
  z-index: 999;
  height: 100px;
  @media (max-width: 767px) {
    height: 60px;
  }
}
.content-limit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 138px;
  pointer-events: none;
  @media (max-width: 767px) {
    width: 80px;
  }
}
.slogan {
  font-size: 14px;
  color: #666666;
  margin-left: 40px;
  flex-shrink: 0;
  line-height: 2;
}

.tabs {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex-direction: row-reverse;
  }
}
.list {
  color: white;
  font-size: 18px;
  display: flex;
  align-items: stretch;
  height: 100px;

  @media (max-width: 767px) {
    display: block;
    position: absolute;
    right: 0;
  }
}
.query {
  margin-left: 120px;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
  .button {
    width: 133px;
    height: 100px;
    display: none;
    cursor: pointer;

    // .button-icon {
    //   width: 70px;
    //   @media (max-width: 767px) {
    //     width: 40px;
    //   }
    // }
    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.phone-content {
  display: flex;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.phone {
  font-size: 24px;
  color: #3295f0;
  display: flex;
  align-items: center;
  margin-left: 60px;
  white-space: nowrap;
  @media (max-width: 767px) {
    margin-left: 10px;
    font-size: 10px;
  }
  .phone-icon {
    width: 26px;
    margin-right: 8px;
    @media (max-width: 767px) {
      width: 12px;
    }
  }
}

.item {
  padding: 0 38px;
  height: 100px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  white-space: nowrap;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: auto;
    padding: 0 20px;
    background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
    height: 60px;
    font-size: 14px;
  }
  &:hover {
    .child {
      display: block;
    }
  }
  .child {
    position: absolute;
    top: 100%;
    padding-top: 16px;
    left: -14px;
    right: -14px;
    display: none;
    .child-inner {
      background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
      padding: 4px;
      border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
      left: auto;
      right: 100%;
      top: 0;
      padding-top: 0;
      padding-right: 30px;
      height: 60px;
    }
    .child-item {
      padding: 0 14px;
      height: 64px;
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      margin-bottom: 4px;
      border-radius: 4px;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
        height: 60px;
      }
    }
    .child-active {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.item:hover,
.active {
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  color: white;
}

.hover {
  position: absolute;
  left: 50%;
  top: 100px;
  height: calc(100% - 100px);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .txt {
    line-height: 1;
    text-align: center;
    padding: 0 20px;
  }
  .digital {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
</style>
