<template>
  <div class="container">
    <top-nav :banner="banner">
      <template #hover>
        <div class="banner-title">
          {{ globalData.developmentApplet.banner.title }}
        </div>
        <pre class="banner-subtitle"
          >{{ globalData.developmentApplet.banner.subtitle }}
          </pre
        >
      </template>
    </top-nav>
    <div class="why">
      <strip-title
        :title="globalData.developmentApplet.why.title"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentApplet.why.list"
          :key="index"
        >
          <img class="icon" :src="why[index]" alt="" />
          <div class="txt">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="genius">
      <strip-title
        :title="globalData.developmentApplet.genius.title"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentApplet.genius.list"
          :key="index"
        >
          <img class="icon" :src="genius[index]" alt="" />
          <div class="title">{{ item.title }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="usual">
      <strip-title
        :title="globalData.developmentApplet.usual.title"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentApplet.usual.list"
          :key="index"
        >
          <img class="icon" :src="usual[index]" alt="" />
          <div class="title">{{ item.title }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="prin">
      <strip-title
        :title="globalData.developmentApplet.prin.title"
        :brief="globalData.developmentApplet.prin.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.developmentApplet.prin.list"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <div class="subtitle">{{ item.subtitle }}</div>
          <pre class="brief">{{ item.brief }}</pre>
        </div>
      </div>
    </div>
    <div class="process content-limit">
      <strip-title
        :title="globalData.development.process.title"
        :brief="globalData.development.process.brief"
      ></strip-title>
      <serve-process></serve-process>
    </div>
    <div class="inventory">
      <strip-title
        :title="globalData.development.inventory.topic.title"
        :brief="globalData.development.inventory.topic.brief"
      ></strip-title>
      <div class="list content-limit">
        <div
          class="item"
          v-for="(item, index) of globalData.development.inventory.list"
          :key="index"
        >
          <img class="icon" :src="inventory[index]" alt="" />
          <pre class="name">{{ item }}</pre>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
  <script>
import { WOW } from "wowjs";
import banner from "../asset/development/dev_a_01.png";
import { mapGetters } from "vuex";
import DeliveryChecklist from "./development/DeliveryChecklist.vue";
import Advantage from "./development/Advantage.vue";
import DevelopmentType from "./development/DevelopmentType.vue";
import process1 from "../asset/development/dev_a_04.png";
import process2 from "../asset/development/dev_a_05.png";
import process3 from "../asset/development/dev_a_06.png";
import process4 from "../asset/development/dev_a_07.png";
import process5 from "../asset/development/dev_a_08.png";
import process6 from "../asset/development/dev_a_09.png";
import process7 from "../asset/development/dev_a_10.png";
import process8 from "../asset/development/dev_a_11.png";
import stripDot from "../asset/development/dev_a_12.png";
import stripArrow from "../asset/development/dev_a_13.png";

import whyic1 from "../asset/development/n_dev_aplt_9.png";
import whyic2 from "../asset/development/n_dev_aplt_23.png";
import whyic3 from "../asset/development/n_dev_aplt_10.png";
import whyic4 from "../asset/development/n_dev_aplt_24.png";
import whyic5 from "../asset/development/n_dev_aplt_25.png";
import whyic6 from "../asset/development/n_dev_aplt_26.png";

import geniusic1 from "../asset/development/n_dev_aplt_11.png";
import geniusic2 from "../asset/development/n_dev_aplt_2.png";
import geniusic3 from "../asset/development/n_dev_aplt_3.png";
import geniusic4 from "../asset/development/n_dev_aplt_4.png";
import geniusic5 from "../asset/development/n_dev_aplt_8.png";
import geniusic6 from "../asset/development/n_dev_aplt_7.png";
import geniusic7 from "../asset/development/n_dev_aplt_6.png";
import geniusic8 from "../asset/development/n_dev_aplt_5.png";

import usualic1 from "../asset/development/n_dev_aplt_15.png";
import usualic2 from "../asset/development/n_dev_aplt_13.png";
import usualic3 from "../asset/development/n_dev_aplt_14.png";
import usualic4 from "../asset/development/n_dev_aplt_12.png";
import usualic5 from "../asset/development/n_dev_aplt_16.png";
import usualic6 from "../asset/development/n_dev_aplt_17.png";
import usualic7 from "../asset/development/n_dev_aplt_18.png";
import usualic8 from "../asset/development/n_dev_aplt_19.png";
import usualic9 from "../asset/development/n_dev_aplt_20.png";
import usualic10 from "../asset/development/n_dev_aplt_21.png";

import invic1 from "../asset/development/n_dev_21.png";
import invic2 from "../asset/development/n_dev_33.png";
import invic3 from "../asset/development/n_dev_6.png";
import invic4 from "../asset/development/n_dev_22.png";
import invic5 from "../asset/development/n_dev_7.png";
import invic6 from "../asset/development/n_dev_24.png";
import invic7 from "../asset/development/n_dev_25.png";
import invic8 from "../asset/development/n_dev_26.png";
import invic9 from "../asset/development/n_dev_8.png";
import invic10 from "../asset/development/n_dev_9.png";
export default {
  components: {
    DeliveryChecklist,
    Advantage,
    DevelopmentType,
  },
  data: function () {
    return {
      banner,
      process: [
        process1,
        process2,
        process3,
        process4,
        process5,
        process6,
        process7,
        process8,
      ],
      stripArrow,
      stripDot,
      why: [whyic1, whyic2, whyic3, whyic4, whyic5, whyic6],
      genius: [
        geniusic1,
        geniusic2,
        geniusic3,
        geniusic4,
        geniusic5,
        geniusic6,
        geniusic7,
        geniusic8,
      ],
      usual: [
        usualic1,
        usualic2,
        usualic3,
        usualic4,
        usualic5,
        usualic6,
        usualic7,
        usualic8,
        usualic9,
        usualic10,
      ],
      inventory: [
        invic1,
        invic2,
        invic3,
        invic4,
        invic5,
        invic6,
        invic7,
        invic8,
        invic9,
        invic10,
      ],
    };
  },
  computed: {
    ...mapGetters(["globalData"]),
  },
  mounted() {
    // this.$store.commit("setBannerChildIndex", 2);
    // this.$nextTick(() => {
    new WOW({
      live: false,
    }).init();
    // })
  },
};
</script>
  <style scoped lang="scss">
.banner-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 64px;
  text-align: left;
  text-align: center;
  color: rgba(51, 132, 232, 1);
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 24px;
  }
}
.banner-subtitle {
  color: white;
  font-size: 27px;
  margin-bottom: 64px;
  text-align: left;
  line-height: 2;
  font-family: "Source Han Sans CN-Medium", serif;
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 18px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.process {
  background: #ffffff;
  padding: 80px 0;
  padding-top: 0;
}

.why {
  padding: 80px 0;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    .item {
      background: url(../asset/development/n_dev_aplt_22.png) no-repeat
        center/contain;
      width: 280px;
      height: 280px;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 100px;
      &:nth-child(3) ~ .item {
        transform: translateX(200px);
      }
      .icon {
        height: 40px;
      }
      .txt {
        font-size: 14px;
        color: white;
        width: 100%;
        text-align: center;
        margin-top: 24px;
      }
    }
  }
}

.genius {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    .item {
      width: calc((100% - 120px) / 4);
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 40px;
      box-shadow: 5px 5px 70px 1px #e8e8e8;
      padding: 40px 0;
      margin-bottom: 40px;
      background: white;

      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(4) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        width: 130px;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        text-align: center;
        margin-top: 24px;
        margin-top: 80px;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        line-height: 2;
        margin: 0;
        margin-top: 15px;
      }
    }
  }
}

.usual {
  padding: 80px 0;
  background: url(../asset/development/n_dev_aplt_27.png) no-repeat center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: center;
    align-content: center;
    .item {
      width: calc((100% - 280px) / 5);
      margin-right: 70px;
      box-shadow: 3px 5px 20px 1px #e0e0e0;
      margin-bottom: 40px;
      background: white;
      text-align: center;
      padding-bottom: 20px;

      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        width: 100%;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      .brief {
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        margin-top: 10px;
      }
    }
  }
}

.prin {
  padding: 80px 0;
  .list {
    display: flex;
    margin-top: 80px;
    .item {
      width: 33%;
      background: linear-gradient(180deg, #bfdeff 0%, #d2f2ff 100%);
      text-align: center;
      padding-top: 78px;
      padding-bottom: 100px;
      &:hover {
        background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
        .title {
          color: rgba(255, 255, 255, 1);
          &::after {
            background: #ffffff;
          }
        }
        .subtitle {
          color: rgba(255, 255, 255, 1);
        }
        .brief {
          color: rgba(255, 255, 255, 1);
          border-color: white;
        }
      }
      .icon {
        width: 100%;
      }
      .title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        width: 100%;
        margin-top: 20px;
        position: relative;
        padding-bottom: 24px;
        &::after {
          content: "";
          position: absolute;
          width: 71px;
          height: 2px;
          background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
          border-radius: 2px 2px 2px 2px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .subtitle {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        margin-top: 64px;
      }
      .brief {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        margin-top: 90px;
        padding: 16px 54px;
        border: 1px solid #3295f0;
        display: inline-block;
      }
    }
  }
}
.inventory {
  padding: 80px 0;
  background: rgba(221, 236, 250, 0.5);
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .item {
      width: calc((100% - 96px) / 5);
      margin-bottom: 24px;
      background: white;
      box-shadow: 3px 5px 8px 1px rgba(170, 170, 170, 0.16);
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 24px;
      color: #333333;
      font-weight: 500;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(5n) ~ .item {
        margin-bottom: 0;
      }
      .icon {
        margin-right: 16px;
      }
    }
  }
}
</style>
